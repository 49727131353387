<template>
  <div style="border: 1px solid #2b2828;max-width: 900px" class="centered">

    <div v-if="messageType === 'club-event'">
      <img v-lazy="astroImg+'?h=200&fit=clip&auto=enhance'" height="200px" width="200px" alt="tulum party support"
           style="margin-left: auto;margin-right: auto;left: 0;right: 0;margin-top: 20px;">
      <p style="font-size: 1.7rem" class="tulum-party-title-alt section-header">RESERVE YOUR SPOT NOW</p>
      <br>
      <p
        style="color: #fff !important; margin-top: -10px;width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;">
        The perfect option for those seeking <strong>upscale experiences.</strong> Ensure your spot, don't miss it out.<br>
      </p>
    </div>

    <div v-if="messageType === 'club-party' ||messageType === 'party'  ">
      <img v-lazy="astroImg+'?h=30'" height="30px" width="30px" alt="tulum party support"
           style="margin-left: auto;margin-right: auto;left: 0;right: 0;margin-top: 20px;">
      <p><strong style="font-size: 1.3rem;margin-top: -10px">INFO & RSVP</strong></p>
      <br>
      <p
        style="color: #fff !important; margin-top: -10px;width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;">
        <strong>Have the party of your dreams with exclusive tables & seats.</strong> Drop us a line and we will be happy to help.
      </p>
    </div>
    <hr>

    <Reservations  :identifier="slug"></Reservations>
    <small style="margin-top: -10px"
    >We operate from 9AM to 10PM / Answer within 30 mins</small>
    <br/>
    <h5 style="font-size: 0.7rem">PLAN A | <strong>SIDE B</strong></h5>
  </div>

</template>
<script>
import Reservations from '../../components/Reservations/Reservations';

export default {
  name: 'EventReservations',
  components: {Reservations},
  props: ['slug'],

  data: () => {
    return {
      astroImg: 'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      diverImg: 'https://imgix.cosmicjs.com/6f7d5cf0-b925-11ef-bee4-3bb1d3c55332-elegant-diver.png',

    };
  },
  computed: {
    messageType: function () {
      if (this.slug.match(/zamna|day-zero|afterlife/)) {
        return 'party'
      }
      if (this.slug.match(/bagatelle|tantra|taboo|rosa-negra/)) {
        return 'club-event'
      }
      return 'club-party'
    }

  },
  methods: {}
};
</script>



